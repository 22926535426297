import TitleUnderline from "components/parts/TitleUnderline";
import React, { useState, useEffect } from "react";
import ProfileSection from "./ProfileSection";
import { Button, CircularProgress, styled } from "@mui/material";
import { useGetUserDetailsQuery } from "features/auth/authApiSlice";
import { useParams } from "react-router-dom";
import BackComp from "components/parts/BackComp";
import AddBillModal from "components/modals/AddBillModal";
import useFetch from "hooks/useFetch";
import BillsAccordion from "components/accordions/BillsAccordion";
import { izypromanService } from "apis";
import { AiOutlineDown } from "react-icons/ai";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import { Modal, TextField, MenuItem, Card, CardContent, Typography } from "@mui/material";
import AccordionDetails from "@mui/material/AccordionDetails";

const MonthlyRentTenantView = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { liveizy_id, apartment_id } = useParams();
  const { data: userData } = useGetUserDetailsQuery(liveizy_id);
  const [openApartment, setOpenApartment] = useState('');
  const [endpoint, setEndpoint] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [type, setType] = useState('monthly_rent');
  const [timeRange, setTimeRange] = useState('');
  const [formloading, setFormLoading] = useState(false);
  const [fetchingRenewals, setFetchingRenewals] = useState(true);
  const [renewals, setRenewals] = useState([]);
  const [editingRenewal, setEditingRenewal] = useState(null);
  const [formVisible, setFormVisible] = useState(false);

  const apartments = `/tenant-apartments/${liveizy_id}`;
  const { data: apartmentData, loading: apartmentIsLoading } = useFetch(izypromanService(), apartments);

  useEffect(() => {
    if (openApartment) {
      setEndpoint(`/bill/get_apartment_bills/${openApartment}`);
    }
  }, [openApartment]);

  const { data, loading, refetch } = useFetch(izypromanService(), endpoint);

  const handleOpen = () => setModalIsOpen(true);
  const handleClose = () => setModalIsOpen(false);

  const handleOpenModal = () => {
    setOpenModal(true);
    fetchRenewals();
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setFormVisible(false);
    setEditingRenewal(null)
  };

  const fetchRenewals = async () => {
    setFetchingRenewals(true);
    try {
      const response = await izypromanService().get(`/renewal-settings?id=${apartment_id}`);
      setRenewals(response.data.data);
    } catch (error) {
      console.error("Error fetching renewals", error);
    } finally {
      setFetchingRenewals(false);
    }
  };

  const handleEdit = (renewal) => {
    setEditingRenewal(renewal);
    setType(renewal.type);
    setTimeRange(renewal.time_range);
    setFormVisible(true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setFormLoading(true);
    const payload = {
      apartmentId: apartment_id,
      timeRange: timeRange,
      type,
    };

    try {
      if (editingRenewal) {
        // PATCH request for editing
        await izypromanService().patch(`/renewal-settings`, payload);
        alert('Renewal updated successfully.');
      } else {
        // POST request for creating
        await izypromanService().post("/renewal-settings", payload);
        alert('Renewal created successfully.');
      }
      fetchRenewals();
      setFormVisible(false);
    } catch (error) {
      alert('Error saving renewal settings.');
    } finally {
      setFormLoading(false);
    }
  };

  if (apartmentIsLoading) return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress /></div>;

  return (
    <div>
      <AddBillModal
        open={modalIsOpen}
        handleClose={handleClose}
        LID={userData?.data?.profile?.liveizy_id}
        AID={apartment_id}
        property={apartment_id}
        refetch={refetch}
      />
      <BackComp />
      <section>
        <TitleUnderline title={"Tenants Details"} />
        <ProfileSection tenant={userData?.data} />
      </section>

      {apartmentData?.data.length === 0 ? (
        <h3 style={{ marginTop: '5rem' }}>This user doesn't belong to any apartment!</h3>
      ) : (apartmentData?.data?.map((el) => (
        <AccordionDiv key={el._id}>
          <AccSummary
            expandIcon={<AiOutlineDown />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <PropertySummary>
              <div style={{ display: 'flex', alignItems: 'center', gap: '7px' }}>
                <h4>
                  <strong>{el?.apartment_id}</strong>
                </h4>
              </div>
              <div>
                <Button
                  sx={{
                    width: "auto",
                    height: "40px",
                    fontSize: "11px",
                    color: "white",
                    background: "#10609F",
                    margin: "10px"
                  }}
                  onClick={handleOpenModal}
                >
                  Set Monthly Renewal
                </Button>
                <Button
                  sx={{
                    width: "auto",
                    height: "40px",
                    fontSize: "11px",
                    color: "white",
                    background: "#10609F",
                  }}
                  onClick={() => setOpenApartment(el?.apartment_id)}
                >
                  View All
                </Button>
              </div>
              <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
              >
                <div style={{
                  background: 'white',
                  padding: '20px',
                  margin: 'auto',
                  marginTop: '10%',
                  width: '500px',
                  borderRadius: '8px',
                  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)'
                }}>
                  <h2 id="modal-title">Renewal Settings</h2>

                  {fetchingRenewals ? (
                    <div style={{ textAlign: 'center' }}>
                      <CircularProgress />
                    </div>
                  ) : (
                    <>
                      {/* Display saved renewals */}
                      {renewals.length > 0 ? (
                        renewals.map((renewal) => (
                          <Card key={renewal.id} style={{ marginBottom: '10px' }}>
                            <CardContent>
                              <Typography variant="h6">Type: {renewal.type === "monthly_rent" ? "Monthly Rent" : "House Rent"}</Typography>
                              <Typography variant="body2">Time Range: {renewal.type === "monthly_rent" ? `${renewal.time_range} hours` : `${renewal.time_range} days`}</Typography>
                              <Typography variant="body2">Apartment ID: {renewal.apartment_id}</Typography>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleEdit(renewal)}
                                style={{ marginTop: '10px' }}
                              >
                                Edit
                              </Button>
                            </CardContent>
                          </Card>
                        ))
                      ) : (
                        <p>No renewals found for this apartment.</p>
                      )}
                    </>
                  )}

                  {/* Create New Renewal Button */}
                  {!formVisible && (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => setFormVisible(true)}
                      style={{ marginTop: '20px', width: '100%' }}
                    >
                      Create New Renewal
                    </Button>
                  )}

                  {/* Form for creating or editing renewal */}
                  {formVisible && (
                    <form onSubmit={handleSubmit} style={{ marginTop: '20px' }}>
                      {/* Apartment ID (autofilled) */}
                      <TextField
                        label="Apartment ID"
                        value={apartment_id}
                        fullWidth
                        margin="normal"
                        disabled
                      />

                      {/* Time Range */}
                      <TextField
                        label="Time Range"
                        value={timeRange}
                        onChange={(e) => setTimeRange(e.target.value)}
                        fullWidth
                        margin="normal"
                        required
                        placeholder="Enter time range (e.g., Mar 2024 to Apr 2024)"
                      />

                      {/* Type */}
                      <TextField
                        label="Type"
                        select
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                        fullWidth
                        margin="normal"
                        required
                      >
                        <MenuItem value="monthly_rent">Monthly Rent</MenuItem>
                        <MenuItem value="house_rent">House Rent</MenuItem>
                      </TextField>

                      {/* Submit Button */}
                      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          disabled={loading}
                        >
                          {loading ? <CircularProgress size={24} /> : editingRenewal ? 'Update Renewal' : 'Create Renewal'}
                        </Button>
                      </div>
                    </form>
                  )}
                </div>
              </Modal>

            </PropertySummary>
          </AccSummary>
          <AccordionDetails style={{ marginTop: '3rem' }}>
            {loading ? (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress />
              </div>
            ) : (
              <>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '1rem' }}>
                  <h2>IzyRent Monthly Bills</h2>
                  <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <Button style={{ width: 'max-content' }} onClick={handleOpen} variant="contained">
                      Add Bill
                    </Button>
                  </div>
                </div>
                <hr />
                {/* Display bills for the selected apartment */}
                {openApartment ? (
                  <BillsAccordion data={data?.data} refetch={refetch} />
                ) : (
                  <p>Select an apartment to view bills.</p>
                )}
              </>
            )}

          </AccordionDetails>
        </AccordionDiv>
      ))
      )}

    </div>
  );
};

export default MonthlyRentTenantView;

const AccordionDiv = styled(Accordion)`
  margin: 20px auto;
  width: 100%;
  padding: 0px;
  height: auto;
  font-size: 14px;
`;

const AccSummary = styled(AccordionSummary)`
  width: 100%;
  padding: 10px;
  height: 70px;
  margin: auto 0;
  background: white;
  box-shadow: 0px 4.2992px 31.1692px -8.59841px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
`;

const PropertySummary = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  width: 100%;
  height: 100%;
`;


