import { toCurrency } from "utils/misc";

export function getBankDetails(params) {
  return `${params.row.account_name} ${params.row.bank_id} ${params.row.account_number}`;
}

export function formatAvailableBalance(params) {
  return `${toCurrency(params.value)}`;
}

export const listOfBanksInNigeria = [
  { id: "1", name: "9mobile 9Payment Service Bank", code: "120001" },
  { id: "2", name: "Abbey Mortgage Bank", code: "801" },
  { id: "3", name: "Above Only MFB", code: "51204" },
  { id: "4", name: "Abulesoro MFB", code: "51312" },
  { id: "5", name: "Access Bank", code: "044" },
  { id: "6", name: "Access Bank (Diamond)", code: "063" },
  { id: "7", name: "Airtel Smartcash PSB", code: "120004" },
  { id: "8", name: "ALAT by WEMA", code: "035A" },
  { id: "9", name: "Amju Unique MFB", code: "50926" },
  { id: "10", name: "Aramoko MFB", code: "50083" },
  { id: "11", name: "ASO Savings and Loans", code: "401" },
  { id: "12", name: "Astrapolaris MFB LTD", code: "MFB50094" },
  { id: "13", name: "Bainescredit MFB", code: "51229" },
  { id: "14", name: "Bowen Microfinance Bank", code: "50931" },
  { id: "15", name: "Carbon", code: "565" },
  { id: "16", name: "CEMCS Microfinance Bank", code: "50823" },
  { id: "17", name: "Chanelle Microfinance Bank Limited", code: "50171" },
  { id: "18", name: "Citibank Nigeria", code: "023" },
  { id: "19", name: "Corestep MFB", code: "50204" },
  { id: "20", name: "Coronation Merchant Bank", code: "559" },
  { id: "21", name: "Crescent MFB", code: "51297" },
  { id: "22", name: "Ecobank Nigeria", code: "050" },
  { id: "23", name: "Ekimogun MFB", code: "50263" },
  { id: "24", name: "Ekondo Microfinance Bank", code: "562" },
  { id: "25", name: "Eyowo", code: "50126" },
  { id: "26", name: "Fidelity Bank", code: "070" },
  { id: "27", name: "Firmus MFB", code: "51314" },
  { id: "28", name: "First Bank of Nigeria", code: "011" },
  { id: "29", name: "First City Monument Bank", code: "214" },
  { id: "30", name: "FSDH Merchant Bank Limited", code: "501" },
  { id: "31", name: "Gateway Mortgage Bank LTD", code: "812" },
  { id: "32", name: "Globus Bank", code: "00103" },
  { id: "33", name: "GoMoney", code: "100022" },
  { id: "34", name: "Guaranty Trust Bank", code: "058" },
  { id: "35", name: "Hackman Microfinance Bank", code: "51251" },
  { id: "36", name: "Hasal Microfinance Bank", code: "50383" },
  { id: "37", name: "Heritage Bank", code: "030" },
  { id: "38", name: "HopePSB", code: "120002" },
  { id: "39", name: "Ibile Microfinance Bank", code: "51244" },
  { id: "40", name: "Ikoyi Osun MFB", code: "50439" },
  { id: "41", name: "Infinity MFB", code: "50457" },
  { id: "42", name: "Jaiz Bank", code: "301" },
  { id: "43", name: "Kadpoly MFB", code: "50502" },
  { id: "44", name: "Keystone Bank", code: "082" },
  { id: "45", name: "Kredi Money MFB LTD", code: "50200" },
  { id: "46", name: "Kuda Bank", code: "50211" },
  { id: "47", name: "Lagos Building Investment Company Plc.", code: "90052" },
  { id: "48", name: "Links MFB", code: "50549" },
  { id: "49", name: "Living Trust Mortgage Bank", code: "031" },
  { id: "50", name: "Lotus Bank", code: "303" },
  { id: "51", name: "Mayfair MFB", code: "50563" },
  { id: "52", name: "Mint MFB", code: "50304" },
  { id: "53", name: "MTN Momo PSB", code: "120003" },
  { id: "54", name: "Paga", code: "100002" },
  { id: "55", name: "PalmPay", code: "999991" },
  { id: "56", name: "Parallex Bank", code: "104" },
  { id: "57", name: "Parkway - ReadyCash", code: "311" },
  { id: "58", name: "Paycom", code: "999992" },
  { id: "59", name: "Petra Mircofinance Bank Plc", code: "50746" },
  { id: "60", name: "Polaris Bank", code: "076" },
  { id: "61", name: "Polyunwana MFB", code: "50864" },
  { id: "62", name: "PremiumTrust Bank", code: "105" },
  { id: "63", name: "Providus Bank", code: "101" },
  { id: "64", name: "QuickFund MFB", code: "51293" },
  { id: "65", name: "Rand Merchant Bank", code: "502" },
  { id: "66", name: "Refuge Mortgage Bank", code: "90067" },
  { id: "67", name: "Rubies MFB", code: "125" },
  { id: "68", name: "Safe Haven MFB", code: "51113" },
  { id: "69", name: "Solid Rock MFB", code: "50800" },
  { id: "70", name: "Sparkle Microfinance Bank", code: "51310" },
  { id: "71", name: "Stanbic IBTC Bank", code: "221" },
  { id: "72", name: "Standard Chartered Bank", code: "068" },
  { id: "73", name: "Stellas MFB", code: "51253" },
  { id: "74", name: "Sterling Bank", code: "232" },
  { id: "75", name: "Suntrust Bank", code: "100" },
  { id: "76", name: "TAJ Bank", code: "302" },
  { id: "77", name: "Tangerine Money", code: "51269" },
  { id: "78", name: "TCF MFB", code: "51211" },
  { id: "79", name: "Titan Bank", code: "102" },
  { id: "80", name: "Titan Paystack", code: "100039" },
  { id: "81", name: "Unical MFB", code: "50871" },
  { id: "82", name: "Union Bank of Nigeria", code: "032" },
  { id: "83", name: "United Bank For Africa", code: "033" },
  { id: "84", name: "Unity Bank", code: "215" },
  { id: "85", name: "VFD Microfinance Bank Limited", code: "566" },
  { id: "86", name: "Wema Bank", code: "035" },
  { id: "87", name: "Zenith Bank", code: "057" },
];

