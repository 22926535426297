import { API_URLS, FRONTEND_URLS } from "./urls";

const axios = require("axios");

let token = localStorage.getItem("Authorization");

if (!token) token = "";

export const authService = () => {
  const authToken = localStorage.getItem("Authorization");
  let url = API_URLS.AUTH_SERVICE_STAGING;

  if (window.location.origin === FRONTEND_URLS.ADMIN_FRONTEND_STAGING) {
    url = API_URLS.AUTH_SERVICE_STAGING;
  } else if (
    window.location.origin === FRONTEND_URLS.ADMIN_FRONTEND_PRODUCTION
  ) {
    url = API_URLS.AUTH_SERVICE_PRODUCTION;
  }

  const instance = axios.create({
    baseURL: url,
    headers: { Authorization: "Bearer " + authToken },
  });

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        localStorage.clear();
        window.location.replace("/login");
      } else {
        return Promise.reject(error);
      }
    }
  );
  return instance;
};

export const walletService = () => {
  const authToken = localStorage.getItem("Authorization");
  let url = API_URLS.WALLET_SERVICE_STAGING;

  if (window.location.origin === FRONTEND_URLS.ADMIN_FRONTEND_STAGING) {
    url = API_URLS.WALLET_SERVICE_STAGING;
  } else if (
    window.location.origin === FRONTEND_URLS.ADMIN_FRONTEND_PRODUCTION
  ) {
    url = API_URLS.WALLET_SERVICE_PRODUCTION;
  }

  const instance = axios.create({
    baseURL: url,
    headers: { Authorization: "Bearer " + authToken },
  });

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        localStorage.clear();
        window.location.replace("/login");
      } else {
        return Promise.reject(error);
      }
    }
  );
  return instance;
};

export const izypromanService = () => {
  const authToken = localStorage.getItem("Authorization");
  let url = API_URLS.IZYPROMAN_DEV;

  if (window.location.origin === FRONTEND_URLS.ADMIN_FRONTEND_STAGING) {
    url = API_URLS.IZYPROMAN_STAGING;
  } else if (
    window.location.origin === FRONTEND_URLS.ADMIN_FRONTEND_PRODUCTION
  ) {
    url = API_URLS.IZYPROMAN_PRODUCTION;
  }

  const instance = axios.create({
    baseURL: url,
    headers: { Authorization: "Bearer " + authToken },
  });

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        localStorage.clear();
        window.location.replace("/login");
      } else {
        return Promise.reject(error);
      }
    }
  );
  return instance;
};
export const izyBizService = () => {
  const authToken = localStorage.getItem("Authorization");
  let url = API_URLS.IZYBIZ_DEV;

  if (window.location.origin === FRONTEND_URLS.ADMIN_FRONTEND_STAGING) {
    url = API_URLS.IZYBIZ_DEV;
  } else if (
    window.location.origin === FRONTEND_URLS.ADMIN_FRONTEND_PRODUCTION
  ) {
    url = API_URLS.IZYBIZ_DEV;
  }

  const instance = axios.create({
    baseURL: url,
    headers: { Authorization: "Bearer " + authToken },
  });

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        localStorage.clear();
        window.location.replace("/login");
      } else {
        return Promise.reject(error);
      }
    }
  );
  return instance;
};
