/* eslint-disable no-unused-vars */
import { Button, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import TextandLogoField from "../../components/parts/TextandLogoField";
import { useAppContext } from "../../context/context";
import { HiOutlinePencilAlt } from "react-icons/hi";
import { BiSearch } from "react-icons/bi";
import ControlledCheckbox from "../../components/parts/ControlledCheckBox";
import DataTable from "../../components/parts/DataTable";
import { authService, izyBizService } from "../../apis";
import { businessTableColumns, usersTableColumns } from "./utils";
import useFetch from "hooks/useFetch";

const Container = styled("div")`
  width: 100%;
`;
const TopSection = styled("section")`
  display: flex;
  align-self: center;
  justify-content: flex-start;
  gap: 50px;
  margin-bottom: 40px;
  width: 100%;
`;

const SearchBar = styled("div")`
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 4px solid rgba(16, 96, 159, 0.4);
  border-radius: 4px;
  height: 60px;
  width: 450px;
`;

const SortSection = styled("div")`
  display: flex;
  flex-direction: column;
`;

const Users = () => {
  const { setNavState, userTablePage, setUserTablePage } = useAppContext();
  const [userView, setUserView] = useState(1);
  const [query, setQuery] = useState("");
  const [tableData, setTableData] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: userTablePage || 1,
    pageSize: 10,
  });

  const bizUsers = `/admin/business-information`;
  const { data, loading } = useFetch(izyBizService(), bizUsers);

  const izyBizUsers = data && data?.data
  
  console.log(izyBizUsers)


  useEffect(() => {
    setNavState("USERS");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    const fetchData = () => {
      setTableData((prev) => ({
        ...prev,
        isLoading: true,
      }));

      authService()
        .get("/admin/users", { params: { page: tableData.page } })
        .then((res) => {
          const data = res.data.data;
          const total = res.data.meta.total;
          const pageSize = res.data.meta.per_page;
          const page = res.data.meta.current_page;
          setTableData((prev) => ({
            ...prev,
            data,
            total,
            page,
            pageSize,
            isLoading: false,
          }));
        })
        .catch((error) => {
          console.log(error);
        });
    };
    fetchData();
  }, [tableData.pageSize, tableData.page]);

  useEffect(() => {
    if (query.length === 8) {
      setTableData((prev) => ({ ...prev, isLoading: true }));
      authService()
        .get(`/admin/users/${query}`)
        .then((res) => {
          setTableData((prev) => ({ ...prev, isLoading: false }));
          setTableData((prev) => ({ ...prev, data: [res.data.data] }));
        })
        .catch((error) => {
          setTableData((prev) => ({ ...prev, isLoading: false }));
          if (error.response.status === 404) {
            return alert("User with Liveizy ID not Found");
          }
          alert(error.response.statusText);
        });
    }
  }, [query]);

  useEffect(() => {
    setUserTablePage(tableData.page);
  }, [tableData.page]);



  return (
    <Container>
      <TopSection>
        <SearchBar>
          <TextandLogoField
            logo={<HiOutlinePencilAlt />}
            placeholder="Search users by name or ID"
            fieldType="text"
            onChange={(e) => setQuery(e.target.value)}
            value={query}
          />
          <Button
            variant="contained"
            startIcon={<BiSearch />}
            sx={{ width: "200px", height: "100%", background: "#10609F" }}
          >
            Search
          </Button>
        </SearchBar>
        {/* <SortSection>
          <p style={{ marginTop: "0px" }}>
            <strong>Sort user list by:</strong>
          </p>
          <div className="position-center" style={{ marginTop: "-15px" }}>
            <ControlledCheckbox
              text={"All"}
              defaultChecked={true}
              size="small"
            />
            <ControlledCheckbox
              text={"Tenants"}
              defaultChecked={false}
              size="small"
            />
            <ControlledCheckbox
              text={"Property Managers"}
              defaultChecked={false}
              size="small"
            />
            <ControlledCheckbox
              text={"Landlords"}
              defaultChecked={false}
              size="small"
            />
          </div>
        </SortSection> */}
      </TopSection>

      <section>
        <div style={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '3.5rem 0'
        }}>
          <ul style={{
            borderRadius: '1rem',
            boxShadow: '1px 1px 1rem rgba(0, 0, 0, 0.2)',
            display: 'flex',
            transition: 'all 0.1s ease-in-out',
          }}>
            <button
              onClick={() => setUserView(1)}
              style={{
                backgroundColor: userView === 1 ? '#10609F' : 'white',
                color: userView === 1 ? 'white' : '#10609F',
                padding: '1rem 2rem',
                borderRadius: '1rem',
                cursor: 'pointer',
                fontWeight: '600',
                textTransform: 'uppercase',
                transition: 'all 0.1s ease-in-out',
              }}
            >
              Liveizy Users
            </button>
            <button
              onClick={() => setUserView(2)}
              style={{
                backgroundColor: userView === 2 ? '#10609F' : 'white',
                color: userView === 2 ? 'white' : '#10609F',
                padding: '1rem 2rem',
                borderRadius: '1rem',
                cursor: 'pointer',
                fontWeight: '600',
                textTransform: 'uppercase',
                transition: 'all 0.1s ease-in-out',
              }}
            >
              IzyBiz Users
            </button>
          </ul>
        </div>
      </section>

      <div>
        {userView === 1 && (
          <DataTable
            autoHeight
            rows={tableData.data}
            columns={usersTableColumns}
            pageSize={tableData.pageSize}
            rowsPerPageOptions={[50]}
            rowCount={tableData.total}
            loading={tableData.isLoading}
            pagination
            page={tableData.page - 1}
            paginationMode="server"
            onPageChange={(newPage) =>
              setTableData((prevState) => ({ ...prevState, page: newPage + 1 }))
            }
            onPageSizeChange={(newPageSize) =>
              setTableData((prevState) => ({
                ...prevState,
                pageSize: newPageSize,
              }))
            }
          />
        )}
        {userView === 2 && (
          <DataTable
            autoHeight
            rows={izyBizUsers?.data}
            columns={businessTableColumns}
            pageSize={izyBizUsers?.pagination.pageSize}
            rowsPerPageOptions={[10]}
            rowCount={izyBizUsers?.pagination.totalItems}
            loading={loading}
            pagination
            page={izyBizUsers?.pagination.currentPage - 1}
            paginationMode="server"
            onPageChange={(newPage) =>
              setTableData((prevState) => ({ ...prevState, page: newPage + 1 }))
            }
            onPageSizeChange={(newPageSize) =>
              setTableData((prevState) => ({
                ...prevState,
                pageSize: newPageSize,
              }))
            }
          />
        )}
       
      </div>
    </Container>
  );
};

export default Users;
