import { Button, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { HiOutlinePencilAlt } from "react-icons/hi";
import { BiSearch } from "react-icons/bi";
import { useAppContext } from "context/context";
import ControlledCheckbox from "components/parts/ControlledCheckBox";
import DataTable from "components/parts/DataTable";
import TextandLogoField from "components/parts/TextandLogoField";
import { useGetUsersIzyInvestPlansQuery } from "./izyInvestApiSlice";
import { formatAvailableBalance } from "./util";
import PayoutDateRangePicker from "./components/PayoutDateRangePicker";
import { toCalenderDate } from "utils/misc";
import { walletService } from "apis";
import useFetch from "hooks/useFetch";

export default function Referral() {
  const { setNavState } = useAppContext();
  const [dateRange, setDateRange] = useState([null, null]);
  const [expires, setExpires] = useState("");
  const { data, isLoading } = useGetUsersIzyInvestPlansQuery(expires);

  useEffect(() => {
    setNavState("izyInvest");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // https://staging-wallet-api.liveizy.com/api/user/banks

  useEffect(() => {
    if (!dateRange[0] || !dateRange[1]) return;
    const [startDate, endDate] = dateRange;

    let expires = `${toCalenderDate(startDate)},${toCalenderDate(endDate)}`;
    setExpires(expires);
  }, [dateRange[1], dateRange[0]]);

  const banksApi = `/user/banks`;
  const { data: banks } = useFetch(walletService(), banksApi);


  const usersPlansTableColumns = [
    // {
    //   field: "fullName",
    //   headerName: "Full Name",
    //   width: 280,
    //   valueGetter: getFullName,
    // },
    {
      field: "available_balance",
      headerName: "Available Bal.",
      width: 190,
      // type: "number",
      flex: 1,
      valueFormatter: formatAvailableBalance,
    },
    {
      field: "end_date",
      headerName: "Payout Date",
      width: 200,
      flex: 1,

      type: "date",
    },
    {
      field: "bankDetails",
      headerName: "Bank Details",
      width: 140,
      flex: 2,
      headerAlign: "center",
      renderCell: (params) => {
        let bank = banks?.data?.banks?.find(
          (bank) => parseInt(bank.code) === parseInt(params.row.bank_id)
        );
        return (
          <div
            style={{
              margin: "0 auto",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <small>{params.row.account_number}</small>
            <small>{bank?.name}</small>
            <small>{params.row.account_name}</small>
          </div>
        );
      },
    },
  ];

  return (
    <Container>
      <TopSection>
        <SearchBar>
          <TextandLogoField
            logo={<HiOutlinePencilAlt />}
            placeholder="Search users by name or ID"
            fieldType="text"
          />
          <Button
            variant="contained"
            startIcon={<BiSearch />}
            sx={{ width: "200px", height: "100%", background: "#10609F" }}
          >
            Search
          </Button>
        </SearchBar>
        <SortSection>
          <p style={{ marginTop: "0px" }}>
            <strong>Sort user list by:</strong>
          </p>
          <div className="position-center">
            <ControlledCheckbox
              text={"All"}
              defaultChecked={true}
              size="small"
            />
            <ControlledCheckbox
              text={"Tenants"}
              defaultChecked={false}
              size="small"
            />
            <ControlledCheckbox
              text={"Property Managers"}
              defaultChecked={false}
              size="small"
            />
            <ControlledCheckbox
              text={"Landlords"}
              defaultChecked={false}
              size="small"
            />
          </div>
        </SortSection>
      </TopSection>
      {/* Table section */}
      <div>
        <DataTable
          autoHeight
          rows={data?.data || []}
          columns={usersPlansTableColumns}
          loading={isLoading}
          components={{
            Toolbar: PayoutDateRangePicker,
          }}
          componentsProps={{
            toolbar: { dateRange, setDateRange },
          }}
        />
      </div>
    </Container>
  );
}

const Container = styled("div")`
  width: 100%;
`;
const TopSection = styled("section")`
  display: flex;
  align-self: center;
  justify-content: flex-start;
  gap: 50px;
  margin-bottom: 40px;
  width: 100%;
`;

const SearchBar = styled("div")`
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 4px solid rgba(16, 96, 159, 0.4);
  border-radius: 4px;
  height: 60px;
  width: 450px;
`;

const SortSection = styled("div")`
  display: flex;
  flex-direction: column;
`;
