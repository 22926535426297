import * as React from "react";
import {
    Modal,
    Box,
    TextField,
    Button,
    Typography,
    Select,
    MenuItem,
    TextareaAutosize,
} from "@mui/material";
import { Formik, Form } from "formik";
import usePost from "hooks/usePost";

export default function EditRenewal({
    open,
    handleClose,
    id
}) {

    const initialValues = {
        adminComments: "",
        acceptanceStatus: '',
        securityDeposit: '',
        contributionFee: '',
    };

    const { mutate } = usePost(`/renewal-review/${id}`, "post", {
        onSuccess: (data) => {
            alert(data.message)
        },
        onError: (data) => {
            alert(data.message);
        },
    });

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 500,
                    bgcolor: "background.paper",
                    border: "1px solid #00000050",
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Review Loan Renewal
                </Typography>
                <Formik
                    initialValues={initialValues}
                    // validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        // console.log(values);
                        mutate(values);
                        setSubmitting(false);
                        handleClose();
                    }}
                >
                    {({
                        values,
                        handleChange,
                        handleBlur,
                        touched,
                        errors,
                        isSubmitting,
                    }) => (
                        <Form>
                            <div style={{ margin: '2rem 0' }}>
                                <h4 style={{marginBottom: '10px'}}>Accept Renewal</h4>
                                <Select
                                    fullWidth
                                    id="acceptanceStatus"
                                    name="acceptanceStatus"
                                    value={values.acceptanceStatus}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.acceptanceStatus && Boolean(errors.acceptanceStatus)}
                                    helperText={touched.acceptanceStatus && errors.acceptanceStatus}
                                >
                                    <MenuItem value={true}>Accept</MenuItem>
                                    <MenuItem value={false}>Reject</MenuItem>
                                </Select>
                            </div>
                        
                            <div style={{ margin: '2rem 0' }}>
                                <h4 style={{ marginBottom: '10px' }}>Security Deposit</h4>
                                <TextField
                                    fullWidth
                                    id="securityDeposit"
                                    name="securityDeposit"
                                    label="Security Deposit:"
                                    type="text"
                                    value={values.securityDeposit}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.securityDeposit && Boolean(errors.securityDeposit)}
                                    helperText={touched.securityDeposit && errors.securityDeposit}
                                />
                            </div>
                            <div style={{ margin: '2rem 0' }}>
                                <h4 style={{ marginBottom: '10px' }}>Contribution Fee</h4>
                                <TextField
                                    fullWidth
                                    id="contributionFee"
                                    name="contributionFee"
                                    label="Contribution Fee:"
                                    type="text"
                                    value={values.contributionFee}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.contributionFee && Boolean(errors.contributionFee)}
                                    helperText={touched.contributionFee && errors.contributionFee}
                                />
                            </div>
                            <div style={{ margin: '2rem 0' }}>
                                <h4 style={{ marginBottom: '10px' }}>Comment</h4>
                                <TextareaAutosize
                                    fullWidth
                                    id="adminComments"
                                    name="adminComments"
                                    label="Comment:"
                                    type="text"
                                    style={{width: '100%', height: '10rem', fontSize: '1.2rem', outline: 'none', padding: '1rem'}}
                                    value={values.adminComments}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.adminComments && Boolean(errors.adminComments)}
                                    helperText={touched.adminComments && errors.adminComments}
                                />
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "end",
                                    marginTop: "3rem",
                                }}
                            >
                                <Button
                                    type="submit"
                                    variant="outlined"
                                    color="primary"
                                    disabled={isSubmitting}
                                >
                                    Continue
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Box>
        </Modal>
    );
}
